import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Card from './card';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`触ったことのあるプログラミング言語・フレームワークや技術を書いていきます。言語・フレームワークの隣に使用歴を書きますが、これはコンスタントに触っていた期間を表します。また、2019 年 11 月 12 日現在までの期間であることをご了承ください。期間が書いてないものは、かじったことがある程度のものや、期間を能力の目安にできないものです。`}</p>
    <ul>
      <li parentName="ul">
        <Card id='python' mdxType="Card">
  : 2 年 ちょい
  <br />
  人生で初めて触ったプログラミングです。競技プログラミングをしたり、twtter bot
  を書いたり、インターンで機械学習的な何かをしていました。割と使える方だと思います。なぜか多層パーセプトロンを自力実装する機会がちょくちょくあります。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='c' mdxType="Card">
  : 6 ヶ月くらい
  <br />
  大学の講義で使いました。深淵に触れる前に触れる機会がなくなってしまったので、もう少し闇をみたかったなと思っています。プログラミングでものを作る難しさと楽しさを教えてくれた言語なので割と感謝しています。講義では
  CUI 上で動作するぷよぷよもどきを作成しました。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='html' mdxType="Card">
  : 1 年ちょい
  <br />
  個人で勉強しました。割と苦手ではないほうかなと思います。サークルのホームページを作ったりしました。人権がある程度には使えると思います。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='react' mdxType="Card">
  : 7 ヶ月ちょい
  <br />
  個人で使っています。昔からグラフィックに関する何かを作るのが好きだったので結構性に合っています。でもサーバサイドも好きなので悩ましいです。大体の処理はかけると思いますが、練度が低いのでまだまだ工数が多くかかります。目下改善したいポイントです。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='gatsby' mdxType="Card">
  : 2 ヶ月
  <br />
  React 製の Blazing fast🔥 なフレームワークです。React
  でのサイト制作は,音に聞く nuxt とかより面倒ごとが多いな〜(webpack
  とか)と思っていたところに現れたので虜になりました。割と Gatsby
  の気持ちを理解し始めてきてはいますが、まだまだ途上です。サイト一つ作るくらいならできると思います。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='javascript' mdxType="Card">
  : 3ヶ月ほど
  <br />
  Reactを書く上で使っている程度なので、JavaScript自体の仕様に詳しいかというとそうではないです。大体の処理は困らずに書けると思います。TypeScriptは最近使い始めましたが、とっても開発体験が良いのでこれからも使い続けると思います。TSとReactを触っているのでElmにも手を出したい...
        </Card>
      </li>
      <li parentName="ul">
        <Card id='haskell' mdxType="Card">
  : 2 ヶ月ちょい
  <br />
  講義で触りました。関数型言語に触れたことがなかった自分にとってとても新鮮で、プログラミング言語自体に対する興味が増したとても思い出深い体験です。「Haskell
  の美しさを知っている人は人生に絶望することはない」、至言だと思います。簡単な四則演算パーサを書いたりしましたが、正直まだ言語に明るいわけではありません。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='r' mdxType="Card">
  : 3 ヶ月ちょい
  <br />
  こちらも初めは講義で。基本的な構文は理解している...といいたいくらいです。あとインターンで案件用のコードを書いたりしました。(それPandasでよくね、とか思ったりしてません。)
        </Card>
      </li>
      <li parentName="ul">
        <Card id='laravel' mdxType="Card">
  : 2 ヶ月
  <br />
  teamLab
  のサマーインターン課題で使用しました。それまでサーバサイドのコードを書いた経験がほぼ無かったので、割と苦しみましたが色々なことを教えてもらいました。MVC
  とかレイヤードアーキテクチャの経験は Laravel
  がベースになっています。過保護めの設計ですが、初心者にはとてもありがたかったのでサーバサイドプログラムへの入門として選ぶのもかなりアリじゃないかなという気がしています。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='rust' mdxType="Card">
  : 3 ヶ月
  <br />
  Rust ってなんかカッコいいよなぁくらいの安直な気持ちで触り始めました。2019
  年のお盆休みは、ずっと Rust
  で競技プログラミングをしていたと思います。例のごとく Twitter bot
  を書いたり、競プロ用ライブラリを整えたり、OAuth1.0
  を自力でやったりしました。とても好きな言語ですがまだ全然Rustの気持ちを理解できていないと思うので、継続的に触っていくと思います。1.39.0がリリースされたので、Rustで非同期処理をやるWebアプリを書いてみたいなと思っています。一番好きな言語は何と聞かれたらRustと答えると思います。そのくらいパラダイムや言語設計が自分にとって革命的でした。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='go' mdxType="Card">
  : 1 ヶ月
  <br />
  teamLab サマーインターンで使用しました。その時は DB アクセスのある
  APIを書きました。構文的な負担が少なくて、とても書きやすい言語だったので、何かWeb
  アプリ作るならバックエンドは
  Goにしようかなと思っています。echoが好き。Gopherくんも可愛くて好きです。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='git' mdxType="Card">
  基本的な機能は大体使えます。インターンのプロジェクトではmaster/develop/feature/releaseブランチを切るモデルを実践したりしてました。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='docker' mdxType="Card">
  インターンの時に開発環境を作ってデプロイするまで使いました。最終的に成果物の
  image
  をビルドしてECRにおいて、Fargateで動かしたりしました。docker-composeも大体使えます。
        </Card>
      </li>
      <li parentName="ul">
        <Card id='nginx' mdxType="Card">
  ISUCON9でインフラ担当をした時に触りました。cacheの設定など、慣れないことで割と苦しんだ記憶がありますがインフラはコードとコマンドでほとんどを管理できるのでやってて楽しかったです。
        </Card>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      